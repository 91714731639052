@import '../../../node_modules/bootstrap/scss/bootstrap.scss';

.container {
    max-width: 1330px;
}




.btn-oval {
    @extend .btn;
    background: $orange;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    border-radius: 55px;
    padding: 16px 36px;

    &:hover {
        background: none;
        border: 1px solid $orange;
        transition: all .2s ease;
        color: $orange;
    }

    &--dark {
        background: #404040;
    }

    &--black {
        background: #000;

        &:hover {
            background: none !important;
            border: 1px solid #000;
            transition: all .2s ease;
            color: #000 !important;
        }
    }

    &--black-border {
        background: #fff;
        border: 1px solid #000;
        color: #000;

        &:hover {
            background: #000 !important;
            border: 1px solid #000;
            transition: all .2s ease;
            color: #fff !important;
        }
    }

    &--border-orange {
        background: #fff;
        border: 1px solid $orange;
        color: $orange;
    }

    &--brown {
        background: #3F2A2C;

        &:hover {
            background: none;
            border: 1px solid #3F2A2C;
            transition: all .2s ease;
            color: #3F2A2C;
        }
    }
}

.form-control:focus {
    box-shadow: none !important;
    border: none !important;
}

.btn-oval-low {
    @extend .btn;
    @extend .btn-light;
    background: #fff;
    color: $orange;
    //border: 1px solid red;
    border-radius: 25px;
    // padding: 10px 25px 5px;
    font-family: Montserrat;
    padding: 11px 28px 12px;
    white-space: nowrap;

    &:hover {
        background: $orange;
        border: 1px solid $orange;
        transition: all .2s ease;
        color: #fff !important;
    }

}

.btn-oval-low--orange {
    background: $orange !important;
    color: #fff !important;
    white-space: nowrap;
    font-family: Montserrat;

    &:hover {
        background: none !important;
        border: 1px solid $orange;
        transition: all .2s ease;
        color: $orange !important;
    }
}

.btn-oval-low--border {
    background: #fff;
    border: 1px solid $orange !important;
    color: #000 !important;
    font-family: Montserrat;
}

.btn-oval-low--dark {
    background: #404040 !important;
    color: #fff !important;
    white-space: nowrap;
    font-family: Montserrat;

    &:hover {
        background: #fff !important;
        border: 1px solid #404040;
        transition: all .2s ease;
        color: #404040 !important;
    }
}

.status {
    img {
        width: 20px;
        height: auto;
    }
}

.modal {
    z-index: 9999;
}

.modal-content {
    border-radius: 12px;
}

////Datepicker
.vd-picker__table-day__current {
    color: #fff !important;
    background: $orange;
}

.vd-picker__table-day__current,
.vd-picker__table-day__effect {
    position: absolute;

    width: 42px;
    height: 42px;
    border-radius: 7px !important;

}

.vd-picker__controls-month {
    justify-content: center !important;
    font-family: Montserrat !important;
    font-weight: 500 !important;
    font-size: 18px !important;
}

.vd-picker__controls svg {
    width: 34px !important;
    height: 25px !important;
    color: $orange !important;
}

.vd-picker__table-day__text {
    font-family: Montserrat !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.vd-picker__table-weekday {
    font-family: Montserrat !important;

    font-weight: 500 !important;
    font-size: 13px !important;
    /* identical to box height */


    color: #8F8F8F !important;
}

.vd-picker__table-day__current+.vd-picker__table-day__effect+.vd-picker__table-day__text {
    color: #000 !important;
    opacity: 0.1 !important;
}



.vd-picker__controls {
    border-bottom: 1px solid #ECECEC;
    margin-bottom: 5px !important;
}

.vd-picker__table {
    height: auto !important;
}

.vd-picker__table-day--selected {
    color: $orange !important;
}

.vd-menu__content {

    // box-shadow: none !important;
    // border-radius: 12px !important;
}

.vd-menu__content {
    box-shadow: none !important;
    border-radius: 12px !important;
}

.vd-picker__table-day__current,
.vd-picker__table-day__effect {
    position: absolute;
    width: 42px !important;
    height: 39px !important;
    border-radius: 7px !important;
}

///////////////////////////////////
.vd-wrapper {
    max-width: 156px;
    margin-left: auto;
    margin-right: auto;

    input {
       // border: none !important;
         border: 1px solid #D3D3D3 ;
        padding: 14px 22px ;
        border-radius: 9px;
        height: 48px ;
        max-height: none !important;
        font-family: Montserrat !important;
        font-size: 16px !important;
       // font-weight: 600;
    }
}

.cabinet__headerMob {
    position: relative;
    font-size: 20px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;

    a {
        display: block;
        position: absolute;
        border: solid #5A5A5A;
        border-width: 0 3px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(135deg);
        left: 0;
        top: 12px;
    }
}
.swiper-pagination-bullets{
    bottom: -20px !important;
}
.swiper-pagination-bullet{
    width: 10px !important;
    height: 10px !important;
    background: none;
    border:1px solid #fff;
    opacity: 1;
    }
    .swiper-pagination-bullet-active{
    background: #fff !important;
    border:1px solid #fff;
    }
    .btn-close{
        @include media-breakpoint-down(md){
            position: absolute;
            background: none;
            right: 0 !important;
            left: 0 !important;
            margin: auto;
            top: -50px;
            opacity: 1;
            width: 32px;
            height: 32px;
        }
       
    }
    .modal-body {
        @include media-breakpoint-down(md){
            padding: 42px 31px 0 32px !important;
        }
     
    }

    .vd-picker__input--is-active{
        input{
     color:#000 !important;
        }
       
    } 
    input[type='number'] {
        -moz-appearance:textfield;
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .vd-picker__input--disabled input {
    opacity: 1 !important;
}
.dayOfBirth {
input{
    border:none !important;
    font-weight: 600;
}
.vd-picker__controls-year{
display: block;
}
.vd-picker__input-icon{ 
    position: relative;
    top: 8px;
    left: 17px;
}
}
.modal{
.vd-picker__controls-year {
    display: none !important;
}
}
