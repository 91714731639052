@import "./src/assets/scss/main.scss";























































.inputs-line {

    input[type="checkbox"],
    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    label {
        display: inline-block;
        background-color: #fff;
        padding: 16px 30px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 109.5%;
        /* or 15px */

        color: #626262;

        border: 1px solid #3F2A2C;
        ;
        margin-bottom: 10px;
        border-radius: 12px;
        margin-right: 10px;
        text-align: center;
        white-space: nowrap;
        // width: 160px;
        cursor: pointer;
        background: #fff;
    }

    // label:hover {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    // input[type="checkbox"]:focus+label {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    input[type="checkbox"]:checked+label,
    input[type="radio"]:checked+label {
        background-color: #3F2A2C;
        border-color: #3F2A2C;
        color: #fff;
    }

    &--orange {
        label {
            background: #F5F5F5;
            border: none;
            padding-left: 40px;
            padding-right: 40px;
            font-size: 18px;
        }

        input[type="checkbox"]:checked+label,
        input[type="radio"]:checked+label {
            background-color: #3F2A2C;
            border-color: #3F2A2C;
            color: #fff;
            border: none;
        }
    }
}
.orange{
       input[type="checkbox"]:checked+label,
        input[type="radio"]:checked+label {
            background-color: $orange;
            border-color: $orange;
            color: #fff;
            border: none;
        }
}
